import React from 'react'

export default function Footer() {
    return (
        <footer>
            <div className='footer-1'>
                <p>MBreu Digital &#45; Todos os direitos reservados.</p>
                {/* <p>MBREU DIGITAL LTDA &#45; CNPJ: 00.000.000/0000-00</p>
                <p>Avenida Luís Viana Filho, 7532 &#45; Conj. Helbor &#45; Torre 02 | Alphaville I | Salvador &#45; Bahia | CEP: 41.701-005</p> */}
            </div>
            <div className='footer-2'>
                <p>Site desenvolvido pela MBREU DIGITAL</p>
            </div>
        </footer>
    )
}