import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ThankYou() {

    const navigate = useNavigate()

    const goToHome = () => {
        navigate("/")
    }

    return (
        <div>
            <div className='contact-info'>
                <h1>Obrigado!</h1>
                <p>Entraremos em contato o mais breve possível!</p>
                <button
                    className='back-button'
                    type='submit'
                    onClick={goToHome}
                >Voltar para o site</button>
            </div>
        </div>
    )
}
