// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCUfUw8SnRNSWy_rnaa7gBawVtQ2TK9PP4",
  authDomain: "mbreu-digital.firebaseapp.com",
  projectId: "mbreu-digital",
  storageBucket: "mbreu-digital.appspot.com",
  messagingSenderId: "68358677769",
  appId: "1:68358677769:web:b7b1c42bbe3ec31be39fd8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app)

export { app, db, auth }