import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { auth } from '../firebaseConfig'
import { signInWithEmailAndPassword } from 'firebase/auth'

export default function Login() {

    const [email, setEmail] = useState("condussolar@gmail.com")
    const [password, setPassword] = useState("")
    const navigate = useNavigate()
    const showPassword = () => {
        const x = document.getElementById("password")
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    const signIn = (e) => {
        e.preventDefault()
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                navigate("/leads")
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div>
            <form
                className='login-form'
                onSubmit={signIn}
            >
                <h1>Faça login abaixo:</h1>
                <label
                    className="login-input-label"
                    htmlFor="nome"
                >
                    Email:<span className='asterisco'> *</span>
                </label>
                <input
                    className='login-input'
                    type='email'
                    placeholder='Digite seu email'
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                ></input><br></br>
                <label
                    className="login-input-label"
                    htmlFor="nome"
                >
                    Senha:<span className='asterisco'> *</span>
                </label>
                <input
                    id='password'
                    className='login-input'
                    type='password'
                    placeholder='Digite sua senha'
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                >
                </input><br></br>
                <div className='container-show-password'>
                    <input
                        className='login-checkbox'
                        type='checkbox'
                        name='show-password'
                        onClick={showPassword}
                    >
                    </input>
                    <label
                        className='login-checkbox-label'
                        htmlFor='show-password'
                    >
                        Mostrar senha
                    </label><br></br>
                </div>
                <button
                    className='login-btn'
                    type='submit'
                >Entrar
                </button><br></br>
            </form>
        </div>
    )
}
