import React, { useState } from 'react'
import { db } from '../firebaseConfig'
import { useEffect } from 'react'
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc
} from 'firebase/firestore'
import { auth } from '../firebaseConfig'
import { onAuthStateChanged, /* signOut */ } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

export default function Leads() {

  const [clients, setClients] = useState([])
  const clientsCollectionRef = collection(db, "clients")
  const [authenticatedUser, setAuthenticatedUser] = useState("")
  const navigate = useNavigate()
  const goToLogin = () => {
    navigate("/login")
  }

  // AUTH
  useEffect(() => {
    const listenAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticatedUser(user)
      } else {
        setAuthenticatedUser(null)
      }
    })
    return () => {
      listenAuth()
    }
  }, [])

  // DELETE OPERATION
  const deleteClient = async (id) => {
    const clientDoc = doc(db, "clients", id)
    await deleteDoc(clientDoc)
    window.location.reload(false)
  }

  // READ OPERATION
  useEffect(() => {
    const getClients = async () => {
      const data = await getDocs(clientsCollectionRef)
      setClients(data.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
      )
      /* console.log("READ OPERATION") */
    }
    getClients()
    // eslint-disable-next-line
  }, [])

  // UPDATE OPERATION
  const checkLead = async (client) => {
    const clientRef = doc(db, 'clients', client.id)
    await updateDoc(clientRef, {
      analisado: !client.analisado
    })
    setClients(prevClients =>
      prevClients.map(prevClient =>
        prevClient.id === client.id
          ? { ...prevClient, analisado: !prevClient.analisado }
          : prevClient
      )
    );
    /* console.log("UPDATE OPERATION") */
  }

  return (
    <div>
      {
        authenticatedUser === null ?
          goToLogin() :
          <div className='leads'>
            <h1 className='clients-title'>Leads</h1>
            <Alert
              variant="success"
              className='clients-user'
            >
              Você está logado como <strong>{authenticatedUser.email}</strong>
            </Alert>
            <div className='leads-new'>
              <p>Total de leads: <span className='leads-count-text'>{clients.length}</span></p>
            </div>
            {
              clients.map((client) => {
                return (
                  <div key={client.id} className='container-client'>
                    <div className='lead-card'>
                      <Button
                        className='lead-button'
                        variant={client.analisado ? "success" : "danger"}
                        onClick={() => checkLead(client)}
                      >
                        {client.analisado ? "Analisado" : "Não analisado"}
                      </Button>
                      <p></p>
                      <p><strong>Nome:</strong></p>
                      <p>{client.name ? client.name : "Cliente não informou o nome."}</p>
                      <p><strong>Telefone:</strong></p>
                      <p>{client.telephone ? client.telephone : "Cliente não informou telefone."}</p>
                      <p><strong>Cidade:</strong></p>
                      <p>{client.city ? client.city : "Cliente não informou a cidade."}</p>
                      <p><strong>Área de atuação da empresa:</strong></p>
                      <p>{client.business ? client.business : "Cliente não informou a área de atuação da empresa."}</p>
                      <p><strong>Mensagem do cliente:</strong></p>
                      <p>{client.message ? client.message : "Cliente não escreveu nenhuma mensagem."}</p>
                      <p><strong>Data de criação do lead:</strong></p>
                      <input
                        className='date'
                        type='text'
                        value={new Date(client.timestamp.seconds * 1000)}
                        disabled
                      >
                      </input><br></br>
                      <button
                        className='delete-button'
                        onClick={() => { deleteClient(client.id) }}
                      >Excluir lead
                      </button>
                    </div>
                  </div>
                )
              })
            }
          </div>
      }
    </div>
  )
}