import React from 'react'
import { Route, Routes } from 'react-router'
import Home from './pages/Home'
import ThankYou from './pages/ThankYou'
import Leads from './pages/Leads'
import Login from './pages/Login'
import Layout from './components/Layout'

export default function App() {
    return (
        <Routes>
            <Route
                path="/"
                element={<Layout />}
            >
                <Route
                    index
                    element={<Home />}
                />
                <Route
                    path="/obrigado"
                    element={<ThankYou />}
                />
                <Route
                    path="/leads"
                    element={<Leads />}
                />
                <Route
                    path="/login"
                    element={<Login />}
                />
            </Route>
        </Routes>
    )
}
