import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebaseConfig'
import {
    collection,
    addDoc,
    serverTimestamp
} from 'firebase/firestore'

export default function Form() {

    const clientsCollectionRef = collection(db, "clients")
    const navigate = useNavigate()

    const [nome, setNome] = useState("")
    const [telefone, setTelefone] = useState("")
    const [cidade, setCidade] = useState("")
    const [negocio, setNegocio] = useState("")
    const [mensagem, setMensagem] = useState("")

    // CREATE OPERATION ON FIREBASE
    const createLead = async () => {
        await addDoc(clientsCollectionRef, {
            name: nome,
            telephone: Number(telefone),
            city: cidade,
            business: negocio,
            message: mensagem,
            analisado: Boolean(false),
            timestamp: serverTimestamp()
        }).then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
        }, function (error) {
            console.log('FAILED...', error);
        });

        navigate("/obrigado")
    }

    return (
        <div>
            <div className='form-header'>
                <h5>Solicite seu orçamento gratuito preenchendo o formulário abaixo!</h5>
            </div>
            <div
                className='form'
            >
                <label
                    className="text-input-label"
                    htmlFor="nome"
                >
                    Nome completo:<span className='asterisco'> *</span>
                </label>
                <input
                    className='text-input'
                    id='nome'
                    name='nome'
                    onChange={(event) => { setNome(event.target.value) }}
                    placeholder='Digite seu nome aqui'
                    value={nome}
                    required
                ></input><br></br>
                <label
                    className="text-input-label"
                    htmlFor="telefone"
                >
                    Telefone (DDD + número):<span className='asterisco'> *</span>
                </label>
                <input
                    type='number'
                    className='text-input'
                    id='telefone'
                    name='telefone'
                    onChange={(event) => { setTelefone(event.target.value) }}
                    placeholder='Digite seu telefone aqui'
                    value={telefone}
                    required
                ></input><br></br>
                <label
                    className="text-input-label"
                    htmlFor="cidade"
                >
                    Cidade:<span className='asterisco'> *</span>
                </label>
                <input
                    className='text-input'
                    id='cidade'
                    name='cidade'
                    onChange={(event) => { setCidade(event.target.value) }}
                    placeholder='Digite sua cidade aqui'
                    value={cidade}
                    required
                ></input><br></br>
                <label
                    className="text-input-label"
                    htmlFor="negocio"
                >
                    Área de atuação da empresa:<span className='asterisco'> *</span>
                </label>
                <input
                    className='text-input'
                    id='negocio'
                    name='negocio'
                    onChange={(event) => { setNegocio(event.target.value) }}
                    placeholder='Digite a área de atuação aqui'
                    value={negocio}
                    required
                ></input><br></br>
                <label
                    for="textarea"
                    className="text-input-label"
                >
                    Escreva uma mensagem:
                </label>

                <textarea
                    className='form-textarea'
                    id="textarea"
                    name="textarea"
                    rows="5"
                    placeholder='Digite sua mensagem...'
                    onChange={(event) => { setMensagem(event.target.value) }}
                    value={mensagem}
                >
                </textarea>
                <button
                    className='submit-button'
                    type='button'
                    onClick={createLead}
                >
                    Enviar pedido de orçamento
                </button>
                <div className='form-privacy'>Sua privacidade é importante para nós!</div>
                <div className='form-privacy'>Seus dados estão protegidos conforme a LGPD (Lei Geral de Proteção de Dados).</div>
            </div>
        </div>
    )
}
