import call from "../assets/images/call_1.png"
import car from "../assets/images/car_1.png"
import graph from "../assets/images/graph_1.png"
import heart from "../assets/images/heart_1.png"
import people from "../assets/images/people_1.png"
import whatsapp from "../assets/images/whatsapp_1.png"
import Form from "../components/Form"
import Accordion from 'react-bootstrap/Accordion'

export default function Home() {
    return (
        <div>
            <div className="gradient">
                <div>
                    <div className="open-image">
                        <h2 className="home--title--1"><span>Integrador Solar</span>, você quer aumentar o faturamento da sua empresa?</h2>
                        <h2 className="home--title--1">Através de anúncios online, isso é possível!</h2>

                        <Form />
                    </div>
                </div>
                <h1 className="objetivos--title-1">Entenda <strong>os benefícios</strong></h1>
                <h3 className="objetivos--title-3">de forma simples e objetiva</h3>
                <div className="objetivos--container--1">
                    <div className="objetivos--container--2">
                        <img
                            src={car}
                            className="objetivos--container--imagem"
                            alt=""
                        />
                        <p className="objetivos--container--texto">Aumento das vendas online e/ou loja física</p>
                    </div>
                </div>
                <div className="objetivos--container--1">
                    <div className="objetivos--container--2">
                        <img
                            src={whatsapp}
                            className="objetivos--container--imagem"
                            alt=""
                        />
                        <p className="objetivos--container--texto">Vendas através de mensagens, contato direto</p>
                    </div>
                </div>
                <div className="objetivos--container--1">
                    <div className="objetivos--container--2">
                        <img
                            src={heart}
                            className="objetivos--container--imagem"
                            alt=""
                        />
                        <p className="objetivos--container--texto">Reconhecimento nas suas redes sociais</p>
                    </div>
                </div>
                <div className="objetivos--container--1">
                    <div className="objetivos--container--2">
                        <img
                            src={graph}
                            className="objetivos--container--imagem"
                            alt=""
                        />
                        <p className="objetivos--container--texto">Dashboard de acompanhamento das campanhas</p>
                    </div>
                </div>
                <div className="objetivos--container--1">
                    <div className="objetivos--container--2">
                        <img
                            src={people}
                            className="objetivos--container--imagem"
                            alt=""
                        />
                        <p className="objetivos--container--texto">Reuniões estratégicas semanais</p>
                    </div>
                </div>
                <div className="objetivos--container--1">
                    <div className="objetivos--container--2">
                        <img
                            src={call}
                            className="objetivos--container--imagem"
                            alt=""
                        />
                        <p className="objetivos--container--texto">Consultoria e suporte para te auxiliar</p>
                    </div>
                </div>
            </div>
            <div className="bonus--container">
                <h1 className="bonus--title--1"><strong>E NÃO ACABOU!</strong></h1>
                <h3 className="bonus--title--2">SÉRIE DE BÔNUS PARA AUMENTAR SEUS RESULTADOS</h3>
                <div className="desconto--container">
                    <div className="desconto-item">
                        <h2>20% de desconto no pix: plano semestral</h2>
                    </div>
                    <div className="desconto-item">
                        <h2>15% de desconto na criação do seu site profissional</h2>
                    </div>
                    <div className="desconto-item">
                        <h2>1 consultoria por mês, para te auxiliar</h2>
                    </div>
                </div>
            </div>
            <h1 className="duvidas-title">DÚVIDAS COMUNS</h1>
            <>
                <Accordion className="accordion-container">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Como funciona o suporte ao cliente?</Accordion.Header>
                        <Accordion.Body className="accordion-body">
                            O suporte é realizado por mim e minha equipe via grupo exclusivo no WhatsApp. Além disso, disponibilizamos a nossa agenda para sempre que você precisar de uma reunião! Você tem direito a uma consultoria por mês, onde eu te explico através de um áudio como funciona.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Preciso investir quanto em anúncio?</Accordion.Header>
                        <Accordion.Body>
                            O Facebook pede no mínimo 6 reais por dia para anunciar; o Google pede 5 reais.<br></br><br></br>
                            Porém, eu solicito que você tenha um poder de investimento maior, para que possamos trabalhar bem e entregar os resultados desejados.<br></br><br></br>
                            Recomendo sempre o valor mínimo de R$ 1.000,00/mês.<br></br>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Como funciona o valor do Gestor de Tráfego?</Accordion.Header>
                        <Accordion.Body>
                            É necessário que você preencha os dados solicitados no formulário, para fazermos uma análise e conseguir passar um orçamento de acordo com as informações que foram disponibilizadas.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Assinatura de tráfego</Accordion.Header>
                        <Accordion.Body>
                            Trabalhamos com assinatura de contrato, onde possui uma permanência mínima de 6 meses do contratante. O contrato possui cláusulas que, se quebradas pelo contratado, podem isentar a sua permanência! Caso contrário, se por um acaso seja necessário o encerramento do vínculo, precisa conversar com o expert (Matheus), para negociarem uma finalização de contrato de forma amigável.<br></br><br></br>
                            O valor precisa ser pago mensalmente. Porém, ao escolher o plano semestral no pix, você recebe um desconto de 20%.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>
            <a
                href="https://wa.me//5571988991670?text=Tenho%20interesse%20em%20fazer%20um%20orçamento%20para%20colocar%20minha%20empresa%20no%20mundo%20digital!"
                target="_blank"
                rel="noreferrer"
            >
                <img
                    src={whatsapp}
                    alt='Logo do WhatsApp'
                    className="whatsapp-button"
                />
            </a>
        </div>
    )
}
